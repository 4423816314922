import React, { useEffect, useState } from 'react';
import Parse from 'node-html-parser';
import logo from './swim-logo.png';
import './App.css';

import SwimmerDataService from './SwimmerDataService';

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

class SwimEntry {
  constructor(course, event, competition, date, time) {
    this.course = course;
    this.event = event;
    this.competition = competition;
    this.date = date;
    this.time = time;
  }
}

function retrieveBestResults(setBestResults, swimID) {
  SwimmerDataService.retrieveSwimmerBestResult(swimID)
    .then(
        response => {
          const temp = Parse(response.data);

          const shortSwim = temp.getElementById('swimmerview-grid');
          const longSwim =  temp.getElementById('swimmerviewlong-grid');

          const list = new Array();

          const shortTRs = shortSwim.getElementsByTagName('tr');
          for (let i = 1; i < shortTRs.length; i++) {
            console.log(shortTRs[i].innerText);
            const shortTDs = shortTRs[i].getElementsByTagName('td');
            list.push(new SwimEntry("25", shortTDs[0].innerText.replace("Swimmer", ""), shortTDs[1].innerText, shortTDs[2].innerText, shortTDs[3].innerText.replace("&nbsp;", "").trim()));
            console.log("xx="+shortTDs[3].innerText+".");
          }
          const longTRs = longSwim.getElementsByTagName('tr');
          for (let i = 1; i < longTRs.length; i++) {
            console.log(longTRs[i].innerText);
            const longTDs = longTRs[i].getElementsByTagName('td');
            list.push(new SwimEntry("50", longTDs[0].innerText.replace("Swimmer", ""), longTDs[1].innerText, longTDs[2].innerText, longTDs[3].innerText.replace("&nbsp;", "").trim()));

          }
          console.log("SIZE=" + list.length)
          setBestResults(list);
        }
    )
}

function loadSwimmer(setBestResults, setSwimID, swimID) {
  setSwimID(swimID);
  retrieveBestResults(setBestResults, swimID);
}



function getResultJSON(bestResults) {
  var content = new Array();
  for (let i = 1; i < bestResults.length; i++) {
    const item = bestResults[i];
    var JSONObj = { "Course":item.course, "Event":item.event, "Competition":item.competition, "Time":item.time, "Date":item.date};
    content.push(JSONObj);
  }
  return content;
}

function App() {
  
  const [bestResults, setBestResults] = useState([]);
  const [swimID, setSwimID] = useState('');

  const [columnDefs] = useState([
    { field: 'Course',  type: 'numberColumn' },
    { field: 'Event' },
    { field: 'Competition' },
    { field: 'Time' },
    { field: 'Date', type: ['dateColumn', 'nonEditableColumn'] }
])

const defaultColDef = {
    // set the default column width
    width: 150,
    // make every column editable
    editable: false,
    // make every column use 'text' filter by default
    filter: 'agTextColumnFilter',
    // enable floating filters by default
    floatingFilter: true,
    // make columns resizable
    resizable: true,
    sortable: true,
  };

 
  return (
    
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Click a swimmer to load personal best timings
        </p>
        <a
          className="App-link"
          href="https://www.tempusopen.se/index.php?r=swimmer/view&id=310961"
          target="_blank"
          rel="noopener noreferrer"
        >
          Olivia Larsson
        </a>
        <a
          className="App-link"
          href="https://www.tempusopen.se/index.php?r=swimmer/view&id=314172"
          target="_blank"
          rel="noopener noreferrer"
        >
          Rasmus Larsson
        </a>
        <a
          className="App-link"
          href="https://svensksimidrott.se/download/18.7d74975f1859fdf1f763640d/1673616918330/Kvaltider%20Sum-Sim%20(50m)%202023-2024.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sum-Sim 2023-2024 kvaltider
        </a>
      </header>
      <div>
        <p>Array size={bestResults.length} : ID={swimID}</p>
        <button onClick={() => loadSwimmer(setBestResults, setSwimID, '310961')}>
          Olivia
        </button>
        <button onClick={() => loadSwimmer(setBestResults, setSwimID, '314172')}>
          Rasmus
        </button>
      </div>
      <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
           <AgGridReact
               rowData={getResultJSON(bestResults)}
               columnDefs={columnDefs}
               defaultColDef={defaultColDef}>
           </AgGridReact>
       </div>
    </div>
    
  );
}

export default App;
