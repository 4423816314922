import axios from 'axios'


// https://www.tempusopen.se/index.php?r=swimmer/view&id=310961
//const BASE_API_URL = 'http://localhost:8080/https://www.tempusopen.se/index.php?'
const BASE_API_URL = 'https://cors-anywhere.herokuapp.com/https://www.tempusopen.se/index.php?'

class SwimmerDataService {

    retrieveSwimmerBestResult(id) {
        //console.log('executed service')

        return axios.get(`${BASE_API_URL}r=swimmer/view&id=${id}`);
      
    }
}

export default new SwimmerDataService()